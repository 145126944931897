import { Button, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import api from "../../utils/api";
import { useAlert } from "../../context/alert";
import { useShows } from "../../context/shows";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Filters from "../layout/Filters";
import Search from "../layout/Search";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { cleanObjFromMongoData } from "./Show";
import { useSavingState } from "../../context/saving";

const useStyles = makeStyles((theme) => ({
  root: {
    opacity: 0.5,
    pointerEvents: "none",
  },
}));

const Shows = ({}) => {
  const history = useHistory();
  const { showAlert } = useAlert();
  const { error } = useSavingState();
  const { shows, setShows, getShows, loading } = useShows();
  const [creatingNewShow, setCreatingNewShow] = useState(false);
  const [deletingShow, setDeletingShow] = useState(null);
  const [copyingShow, setCopyingShow] = useState("");
  const classes = useStyles();

  const createNewShow = () => {
    setCreatingNewShow(true);
    api
      .post(`/shows`, {
        name: "New Show Name",
        store: "Enter Store",
      })
      .then((res) => {
        history.push(`/shows/${res.data._id}`);
      });
  };

  const deleteShow = (id) => {
    if (window.confirm("Are you sure you want to delete this show?")) {
      setDeletingShow(id);
      api
        .delete(`/shows/${id}`)
        .then(() => document.dispatchEvent(new CustomEvent("update-shows-please")))
        .finally(() => setDeletingShow(false));
    }
  };

  const duplicateProduct = async (product, newShowId) => {
    product.show = newShowId;
    product = cleanObjFromMongoData(product);
    product.variants = product.variants.map((variant) => {
      variant = cleanObjFromMongoData(variant);
      variant.sizes = variant.sizes.map(cleanObjFromMongoData);
      return variant;
    });
    let variants = product.variants;
    product.variants = undefined;
    const { data: newProduct } = await api.post("/products", product);
    variants = variants.map((variant) => ({
      ...variant,
      product: newProduct._id,
    }));
    if (variants.length) {
      await api.post("/variants", variants);
    }
  };

  const duplicateShow = async (showId) => {
    setCopyingShow(showId);
    let { data: show } = await api.get(`/shows/${showId}`, {
      params: {
        populate: {
          path: "products",
          populate: "variants",
        },
      },
    });
    show = cleanObjFromMongoData(show);
    const products = show.products;
    delete show.products;
    let { data: newShow } = await api.post("/shows", show);
    await Promise.all(products.map((product) => duplicateProduct(product, newShow._id)));
    history.push(`/shows/${newShow._id}`);
  };

  const updateShow = (id, update) => {
    return api.put(`/shows/${id}`, update).then((r) => {
      document.dispatchEvent(new CustomEvent("update-shows-please"));
      return r;
    }).catch(error);
  };

  if (!shows.matchedSearch)
    return (
      <div className="center">
        <CircularProgress />
      </div>
    );

  return (
    <>
      <List style={{ width: "100%", ...(loading ? { opacity: 0.5, userSelect: "none" } : undefined) }}>
        <SearchAndFilter
          searchProps={{ keysToSearchIn: ["PO", "name"], listOfObjects: shows.originalRes, onListFiltered: (newList) => setShows((e) => ({ ...e, matchedSearch: newList })) }}
          filterProps={{ onChangeParams: getShows }}
        />
        {shows.matchedSearch.map((show, i, a) => {
          return (
            <EachList
              {...{
                key: show._id,
                i,
                duplicateShow,
                copyingShow,
                showAlert,
                show,
                deletingShow,
                classes,
                history,
                updateShow,
                deleteShow,
                a,
              }}
            />
          );
        })}

        <br />
        <br />
        <div style={{ position: "sticky", bottom: 5, marginLeft: "calc(50% - 60px)" }}>
          <Button variant="contained" color="primary" disabled={creatingNewShow} onClick={createNewShow}>
            + New Show
          </Button>
        </div>
      </List>
    </>
  );
};

export default Shows;

function EachList({ i, a, show, deletingShow, classes, history, updateShow, deleteShow, duplicateShow, copyingShow, showAlert }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Fragment>
      <ListItem alignItems="flex-start" classes={show._id === deletingShow ? classes : {}} onClick={() => history.push(`/shows/${show._id}`)} style={{ cursor: "pointer" }}>
        <ListItemText
          primary={
            <span
              dangerouslySetInnerHTML={{
                __html: (show.PO ? `${show.PO} - ` : "") + show.name + (show.date ? " - " + new Date(show.date).toLocaleDateString() : "") + (show.store ? `- ${show.store.indexOf(' - ')}` : "") + (show.defaultProductSeason ? ` - ${show.defaultProductSeason}` : "" ),
              }}
            />
          }
        />
        <ListItemSecondaryAction classes={show._id === deletingShow ? classes : {}}>
          <IconButton
            edge="end"
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {!show.doneEditing && (
              <MenuItem
                onClick={(e) => {
                  const ready = `${show.readyForReview ? "not " : ""}ready`;
                  if (!window.confirm(`Are you sure you want to mark this as ${ready} for review?`)) return;
                  updateShow(show._id, { readyForReview: !show.readyForReview }).then(() => {
                    showAlert({ message: `Show marked ${ready} for review` });
                  });
                  e.stopPropagation();
                  handleClose();
                }}
              >
                Mark {show.readyForReview ? "not " : ""}ready for review
              </MenuItem>
            )}
            {show.readyForReview && (
              <MenuItem
                onClick={(e) => {
                  const done = `${show.doneEditing ? "not " : ""}done`;
                  if (!window.confirm(`Are you sure you want to mark this as ${done}?`)) return;
                  updateShow(show._id, { doneEditing: !show.doneEditing }).then(() => {
                    showAlert({ message: `Show marked as ${done}` });
                  });
                  e.stopPropagation();
                  handleClose();
                }}
              >
                Mark {show.doneEditing ? "not " : ""}done
              </MenuItem>
            )}

            <a href={`${process?.env.REACT_APP_API_ENDPOINT}/shows/${show._id}/downloadCSV/vrp`} download style={{ color: "unset", textDecoration: "none" }}>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                }}
              >
                Export VRP
              </MenuItem>
            </a>
            <a href={`${process?.env.REACT_APP_API_ENDPOINT}/shows/${show._id}/downloadCSV/vendor`} download style={{ color: "unset", textDecoration: "none" }}>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                }}
              >
                Export Vendor{" "}
              </MenuItem>
            </a>
            <a href={`${process?.env.REACT_APP_API_ENDPOINT}/shows/${show._id}/downloadCSV/vrp?store=LT`} download style={{ color: "unset", textDecoration: "none" }}>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                }}
              >
                Export VRP LT
              </MenuItem>
            </a>
            <a href={`${process?.env.REACT_APP_API_ENDPOINT}/shows/${show._id}/downloadCSV/vendor?store=LT`} download style={{ color: "unset", textDecoration: "none" }}>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                }}
              >
                Export Vendor LT
              </MenuItem>
            </a>
            <a href={`${process?.env.REACT_APP_API_ENDPOINT}/shows/${show._id}/downloadCSV/vrp?store=LW`} download style={{ color: "unset", textDecoration: "none" }}>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                }}
              >
                Export VRP LW
              </MenuItem>
            </a>
        
         
            <a href={`${process?.env.REACT_APP_API_ENDPOINT}/shows/${show._id}/downloadCSV/vendor?store=LW`} download style={{ color: "unset", textDecoration: "none" }}>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                }}
              >
                Export Vendor LW
              </MenuItem>
            </a>
            <MenuItem
              onClick={(e) => {
                updateShow(show._id, { archived: !show.archived }).then(() => {
                  showAlert({ message: `Show ${!show.archived ? "Archived" : "Unarchived"}` });
                });
                e.stopPropagation();
                handleClose();
              }}
            >
              {show.archived ? "Unarchive" : "Archive"}
            </MenuItem>
            {show.archived && (
              <MenuItem
                onClick={(e) => {
                  updateShow(show._id, { completed: !show.completed }).then((r) => {
                    showAlert({ message: `Show marked as ${!r.data.completed ? "not" : ""} completed` });
                  });
                  e.stopPropagation();
                  handleClose();
                }}
              >
                Mark as {show.completed && "not "} completed
              </MenuItem>
            )}
            <MenuItem
              onClick={(e) => {
                duplicateShow(show._id);
                e.stopPropagation();
                handleClose();
              }}
              disabled={show._id === copyingShow}
            >
              {show._id === copyingShow && <CircularProgress size={24} />} Duplicate
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                deleteShow(show._id);
                e.stopPropagation();
                handleClose();
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
      {i + 1 !== a.length && <Divider component="li" />}
    </Fragment>
  );
}

export function SearchAndFilter({ searchProps, filterProps }) {
  const searchFilterCont = useRef();

  const filterChipsRef = useRef();
  useEffect(() => {
    function headerIn() {
      searchFilterCont.current.style.top = "64px";
    }
    function headerOut() {
      searchFilterCont.current.style.top = "0";
    }
    window.document.addEventListener("header-in", headerIn);
    window.document.addEventListener("header-out", headerOut);
    return () => {
      window.document.removeEventListener("header-in", headerIn);
      window.document.removeEventListener("header-out", headerOut);
    };
  }, []);

  return (
    <>
      <Grid container spacing={2} style={{ background: "white", position: "sticky", top: 0, zIndex: 999 }} ref={searchFilterCont}>
        <Grid item xs={9}>
          <Search {...searchProps} />
        </Grid>
        <Grid item xs={3}>
          <Filters {...{ ...filterProps, portal: filterChipsRef?.current }} />
        </Grid>
      </Grid>

      <br></br>
      <div ref={filterChipsRef}></div>
    </>
  );
}
